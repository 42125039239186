<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          Your Receipts
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-select
          v-model="query.term"
          class="mr10"
          :clearable="true"
          placeholder="Select Term"
        >
          <el-option
            v-for="item in options.termList"
            :key="item.value"
            :label="item.description"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="query.promotionType"
          class="mr10"
          placeholder="Select Promotion"
        >
          <el-option
            v-for="item in options.promotionOptions"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'CreatedTime', order: 'ascending' }"
      >
        <el-table-column
          prop="billTo"
          label="Bill To"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="billDate"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="BillDate"
          :formatter="$tableCellFormatter.formatDate"
          label="Bill Date"
          width="90"
        ></el-table-column>
        <el-table-column prop="term" label="Term" width="80"></el-table-column>
        <el-table-column
          prop="promotionType"
          label="Promotion Type"
          width="110"
        ></el-table-column>
        <el-table-column
          prop="gst"
          label="GST(%)"
          :formatter="$tableCellFormatter.formatDecimal"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="discount"
          label="Discount(%)"
          :formatter="$tableCellFormatter.formatDecimal"
          width="90"
        ></el-table-column>
        <el-table-column prop="description" label="Items">
          <template #default="scope">
            <span v-html="parseDescription(scope.row.description)"></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="netTotal"
          label="Subtotal"
          :formatter="$tableCellFormatter.formatDecimal"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="discountAmount"
          label="Discount"
          :formatter="$tableCellFormatter.formatDecimal"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="total"
          label="Total"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Total"
          :formatter="$tableCellFormatter.formatDecimal"
          width="90"
        ></el-table-column>
        <el-table-column label="Operation" width="100" align="center">
          <template #default="scope">
            <el-button type="text" @click="handleView(scope.$index, scope.row)">
              <el-icon>
                <view />
              </el-icon>
              View
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from "../../service/api";

export default {
  name: "receiptList",
  data() {
    return {
      controllerUrl: "/receipt",
      query: {
        studentId: null,
        receiptId: null,
        term: null,
        promotionType: null,
        orderBy: "CreatedTime",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: 20,
      },
      options: {
        promotionOptions: [
          {
            label: "All",
            value: "",
          },
          {
            label: "Normal",
            value: "Normal",
          },
          {
            label: "EarlyBird",
            value: "EarlyBird",
          },
        ],
        termList: [],
      },
      tableData: [],
      itemTotal: 0,
      pageTotal: 0,
      form: {},
    };
  },
  created() {
    this.options.termList = this.$appSetting.globalConfig.termList;
    let studentId = this.$user.getUsername();
    console.log("StudentReceiptList.created", studentId);
    if (studentId) {
      this.query.studentId = studentId;
      this.loadData();
    }
  },
  methods: {
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        console.log(res);

        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection =
          sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    parseDescription(des) {
      if (!des) return "";
      return des.replace("|", "<br>");
    },
    handleView(index, row) {
      console.log(row);
      this.form = row;
      this.$router.push({
        path: "/receiptDetail",
        query: { receiptId: row.receiptId },
      });
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
